// TODO: Check other hooks vs onClientEntry
// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
import { getCampaignCode } from '@constants/dataLayer'
import { setCampaign } from '@utils/Cookies'

/**
 * @type {import('gatsby').GatsbyBrowser['onClientEntry']}
 */
const onClientEntry = () => {
  //  const griddoWebfonts = document.getElementById('griddo-webfonts')
  //
  //  if (griddoWebfonts) {
  //    griddoWebfonts.setAttribute('rel', 'stylesheet')
  //  }
  //
  //  if ('fonts' in document) {
  //    document.fonts.load('1em Cooper').then(function() {
  //      if (document.fonts.check('1em Cooper')) {
  //        document.body.classList.add('font-load')
  //      }
  //    })
  //  }

  setCampaign().catch((e) => console.log('Error set campaign: ', e.toString()))

  if (typeof window !== 'undefined') {
    try {
      const pageView = JSON.parse(window.sessionStorage.getItem('page_view'))
      if (pageView) {
        pageView.promomodalidad = getCampaignCode()
        pageView.full_url = window.location.href
        window.dataLayer.push(pageView)
      } else {
        window.addEventListener('pageViewAdded', () => {
          console.log('Evento pageViewAdded lanzado')
          const pageView = JSON.parse(
            window.sessionStorage.getItem('page_view')
          )
          pageView.promomodalidad = getCampaignCode()
          window.dataLayer.push(pageView)
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  onClientEntry,
}
