import components from './src/models/schemas/components'
import modules from './src/models/schemas/modules'
import templates from './src/models/schemas/templates'
import dataPacks from './src/models/data/packs'
import dataPacksCategories from './src/models/data/packs/categories'
import structuredData from './src/models/data/structuredData'
import structuredDataTaxonomies from './src/models/data/categories'

// other schemas
import languages from './src/models/data/languages'
import menus from './src/models/schemas/menus'
import menuItems from './src/models/schemas/config/menu-items'
import { moduleCategories } from '@constants/module-categories'
import griddoConfig from './src/griddo'

// Compose themes for AX
const { themes: allThemes } = griddoConfig
const themes = allThemes.map((theme) => ({
  label: theme.name,
  value: theme.id,
}))

const schemas = {
  config: {
    menus,
    themes,
    languages,
    menuItems,
    moduleCategories,
  },

  ui: {
    modules,
    templates,
    components,
  },

  contentTypes: {
    dataPacks,
    dataPacksCategories,
    structuredData: { ...structuredData, ...structuredDataTaxonomies },
  },
}

export default { schemas }
