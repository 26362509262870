export default {
  // esto es un ejemplo
  // GRIDDO_DATA_CATEGORY: {
  //   title: 'News Areas',
  //   dataPacks: ['GRIDDO_PACK'],
  //   local: true,
  //   taxonomy: true,
  //   fromPage: false,
  //   translate: true,
  //   schema: null,
  //   clone: null,
  //   defaults: null,
  // },
}
