/**
 * Warns!
 * Everything imported here is added to the final bundle.
 */

// React components, modules and templates
import components from '@components'
import modules from '@modules'
import * as templates from '@templates'

// Providers
// import { SiteProvider } from '@griddo-root'
import { SiteProvider } from '@adapters/index'

// Config file for the build process (CX)
import ssr from '../builder.ssr'
import browser from '../builder.browser'

// Adapted elements
// import { mapLibrary, SiteProvider } from '@adapters'

// DataLayer constants
import {
  DATALAYER_MODULES,
  DataLayerCustomEventsExtraParams,
  mountParamsPage,
} from '@constants/dataLayer'

// Wrapped components
// const bundle = mapLibrary({ ...components, ...modules })
const bundle = { ...components, ...modules }

// Dimensions
const generateAutomaticDimensions = (page) => {
  const {
    location: { pathname },
    pageContext: {
      locale,
      page: {
        template: { templateType },
        isHome,
        fullPath,
      },
    },
  } = page

  const data = mountParamsPage({
    locale,
    isHome,
    templateType,
    pathname,
    eventName: 'page_view',
    isCX: true,
  })

  if (fullPath.page === '/actualidad/' || fullPath.page === '/actualitat/') {
    data.page_section = 'blog'
  }

  const dateClone = { ...data }
  dateClone?.promomodalidad && delete dateClone?.promomodalidad
  const pageView = JSON.stringify({ ...dateClone })

  if (templateType === 'ProgramDetail') {
    const {
      pageContext: {
        page: {
          template: { programTitle, identifier, area, programType, price },
        },
      },
    } = page

    const extraData = {
      template: {
        programTitle,
        identifier,
        area,
        programType,
        price,
      },
      _dataLayer: { ...DataLayerCustomEventsExtraParams },
      module: DATALAYER_MODULES.HEADER,
    }

    data.__HIDDEN = `__SCRIPT:(() => {     
        window.sessionStorage.removeItem('item_list_name')
        const prevPageView = JSON.parse(
           window.sessionStorage.getItem('page_view')
        ) || null
        
        const clickEventLink = JSON.parse(
          window.sessionStorage.getItem('click-event-link')
        ) || null
        

        if( !!clickEventLink && window?.location.href === clickEventLink?.url) {
           const { template, _dataLayer, module } = ${JSON.stringify(extraData)}
          
           _dataLayer.module = clickEventLink?.type || module
           _dataLayer.event = 'custom_event'
           _dataLayer.event_name = 'select_item'
          
           _dataLayer.product_name = template?.programTitle?.content || undefined
           _dataLayer.product_id = template?.identifier || 'undefined'

           if (template?.area[0]?.content) {
            _dataLayer.product_category = template.area[0].content?.title || undefined
           } else {
            _dataLayer.product_category = template?.area[0]?.title || undefined
           }

           if (template?.programType[0]?.content) {
            _dataLayer.product_category_2 =
            template.programType[0].content?.title || undefined
           } else {
            _dataLayer.product_category_2 = template?.programType[0]?.title || undefined
           }

           _dataLayer.ecommerce.items[0].item_id = _dataLayer.product_id
           _dataLayer.ecommerce.items[0].item_category = _dataLayer.product_category
           _dataLayer.ecommerce.items[0].item_category_2 = _dataLayer.product_category_2
           _dataLayer.ecommerce.items[0].item_name = _dataLayer.product_name
           _dataLayer.ecommerce.items[0].item_list_name = _dataLayer.product_name
           _dataLayer.ecommerce.items[0].quantity = '1'
           _dataLayer.ecommerce.items[0].currency = 'EUR'
           _dataLayer.ecommerce.items[0].price = template?.price.replaceAll('.', '').split('€').join('').split(' ').join('') || '1'
           
            if( !!prevPageView ) {
              _dataLayer.brand = prevPageView.brand
              _dataLayer.country_site = prevPageView.brand
              _dataLayer.language_site = prevPageView.country_site
              _dataLayer.page_name = prevPageView.page_name
              _dataLayer.page_section = prevPageView.page_section
              _dataLayer.page_type = prevPageView.page_type
              _dataLayer.platform = prevPageView.platform
              _dataLayer.promomodalidad = prevPageView.promomodalidad
              _dataLayer.ecommerce.items[0].item_list_name = prevPageView.page_name
            }
           
           dataLayer.push(_dataLayer)
        }
        
        if( !!clickEventLink && !!prevPageView) {
            window.sessionStorage.setItem('item_list_name', prevPageView.page_name)
        }
        
        window.sessionStorage.removeItem('click-event-link')
        window.sessionStorage.removeItem('page-view')
        
        const prevTemplate = sessionStorage.getItem('current_template')
        if (prevTemplate) {
          sessionStorage.setItem('prev_template', prevTemplate)
        }
        sessionStorage.setItem('current_template', ${JSON.stringify(
          templateType
        )})
        
        window.sessionStorage.setItem('page_view', ${JSON.stringify(pageView)})
        window.dispatchEvent(new Event('pageViewAdded'));
     })()`
  } else {
    data.__HIDDEN = `__SCRIPT:(() => {
        window.sessionStorage.removeItem('item_list_name')
        
        const prevPageView = JSON.parse(
           window.sessionStorage.getItem('prev_view')
        ) || null
        
        const clickEventLink = JSON.parse(
          window.sessionStorage.getItem('click-event-link')
        ) || null
        
        const prevTemplate = sessionStorage.getItem('current_template')
        const currentTemplate = ${JSON.stringify(templateType)}
        
        if( !!clickEventLink && !!prevPageView || !!prevPageView && currentTemplate === 'ApplyForm') {
            window.sessionStorage.setItem('item_list_name', prevPageView.page_name)
        }
        
        window.sessionStorage.removeItem('click-event-link')
        window.sessionStorage.removeItem('page-view')
            
        if (prevTemplate) {
          sessionStorage.setItem('prev_template', prevTemplate)
        }
        
        sessionStorage.setItem('current_template', ${JSON.stringify(
          templateType
        )})
        window.sessionStorage.setItem('page_view', ${JSON.stringify(pageView)})
        window.dispatchEvent(new Event('pageViewAdded'));
     })()`
  }

  delete data.event
  delete data.event_name
  delete data.brand
  delete data.country_site
  delete data.language_site
  delete data.platform
  delete data.page_section
  delete data.page_name
  delete data.page_type
  delete data.promomodalidad

  return data
}

// Library export
export {
  bundle as components,
  templates,
  ssr,
  browser,
  generateAutomaticDimensions,
  SiteProvider,
}
